<template>
  <div class="song-details-page">
    <!-- App Bar with gradient -->
    <header class="app-bar">
      <div class="back-button">
        <router-link to="/" class="back-link">
          <span class="back-icon">←</span>
          <span>Back</span>
        </router-link>
      </div>
    </header>

    <!-- Retro Player Container -->
    <div class="retro-player-container" v-if="song">
      <!-- Retro Player UI -->
      <div class="retro-player">
        <!-- Left Panel with Visualizer and Thumbnail -->
        <div class="player-panel visualizer-panel">
          <div class="time-display">{{ formatTime(currentTime) }}</div>

          <!-- Song Thumbnail Display -->
          <div class="thumbnail-display">
            <img
              :src="thumbnailUrl"
              alt="Song Cover"
              class="song-thumbnail"
              v-if="thumbnailUrl"
            />
          </div>

          <!-- Audio Visualizer -->
          <div class="visualizer">
            <div
              class="visualizer-bar"
              v-for="(bar, index) in visualizerBars"
              :key="index"
              :style="{ height: `${bar}%`, backgroundColor: getBarColor(bar) }"
            ></div>
          </div>
        </div>

        <!-- Right Panel with Track Info -->
        <div class="player-panel info-panel">
          <div class="track-title">{{ song.song_title }}</div>
          <div class="track-artist">DJ Chris</div>
          <!-- Additional track info -->
          <div class="track-meta">
            <div class="meta-item">{{ getReleaseYear() }}</div>
            <div class="meta-divider">/</div>
            <div class="meta-item">320 kbps</div>
            <div class="meta-divider">/</div>
            <div class="meta-item">44 kHz</div>
          </div>

          <!-- Social Media Links -->
          <div class="social-links">
            <a
              v-if="song.youtube_url"
              :href="song.youtube_url"
              target="_blank"
              class="social-button youtube-button"
            >
              <span class="button-icon">YT</span>
            </a>
            <a
              v-if="song.tik_tok_url"
              :href="song.tik_tok_url"
              target="_blank"
              class="social-button tiktok-button"
            >
              <span class="button-icon">TT</span>
            </a>
          </div>
        </div>
      </div>
      <!-- Main Playback Slider with seeking functionality -->
      <div class="main-playback-control">
        <div
          class="progress-track"
          ref="progressTrack"
          @mousedown="onMouseDown"
          @touchstart="onTouchStart"
        >
          <div
            class="progress-fill"
            :style="{
              width: `${(currentTime / duration) * 100}%`,
              backgroundColor: progressColor,
            }"
          ></div>
        </div>
        <div
          class="progress-handle"
          :style="{ left: `${(currentTime / duration) * 100}%` }"
        ></div>
      </div>

      <!-- Main Control Buttons -->
      <div class="main-controls">
        <button class="control-button play-button" @click="togglePlay">
          <span class="button-icon">{{ isPlaying ? "⏸" : "▶" }}</span>
        </button>
        <button class="control-button stop-button" @click="stopTrack">
          <span class="button-icon">⏹</span>
        </button>
        <button class="control-button download-button" @click="triggerDownload">
          <span class="button-icon">↓</span>
        </button>
      </div>

      <!-- Hidden Audio Element -->
      <audio
        ref="audioPlayer"
        :src="songDownloadUrl"
        @timeupdate="updateCurrentTime"
        @loadedmetadata="setDuration"
        @ended="handleAudioEnded"
      ></audio>
    </div>

    <!-- Loading State -->
    <div v-else class="loading-container">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script setup>
import { getDownloadUrl, getSongById, getThumbnailUrl } from "@/api/services";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";

// Route and song info
const route = useRoute();
const songId = computed(() => route.params.songId);

const song = ref(null);
const thumbnailUrl = ref(null);
const songDownloadUrl = ref(null);
const audioPlayer = ref(null);

// Player state
const isPlaying = ref(false);
const currentTime = ref(0);
const duration = ref(0);

// Visualizer
const visualizerBars = ref(Array(18).fill(10));
let visualizerInterval = null;

// Refs for new slider control
const progressTrack = ref(null);
const isDragging = ref(false);
const dragTime = ref(0);

// Computed property for dynamic progress color
const progressColor = computed(() => {
  const progress = duration.value ? currentTime.value / duration.value : 0;
  // Hue transitions from red (0 deg) to green (120 deg)
  const hue = progress * 120;
  return `hsl(${Math.floor(hue)}, 100%, 50%)`;
});

// Function to extract year from created_at
function getReleaseYear() {
  if (!song.value || !song.value.created_at) return "";
  return new Date(song.value.created_at).getFullYear();
}

onMounted(async () => {
  window.scrollTo(0, 0); // Reset scroll position to the top
  document.body.style.backgroundColor = "#121212";

  try {
    song.value = await getSongById(songId.value);
    if (song.value) {
      songDownloadUrl.value = getDownloadUrl(song.value.file_name);
      thumbnailUrl.value = getThumbnailUrl(song.value.thumbnail_name);
      // Start visualizer animation
      startVisualizer();
    }
  } catch (error) {
    console.error("Error fetching song:", error);
  }
});

onUnmounted(() => {
  if (visualizerInterval) {
    clearInterval(visualizerInterval);
  }
  window.removeEventListener("mousemove", onMouseMove);
  window.removeEventListener("mouseup", onMouseUp);
  window.removeEventListener("touchmove", onTouchMove);
  window.removeEventListener("touchend", onTouchEnd);
});

// Visualizer functions
function startVisualizer() {
  if (visualizerInterval) clearInterval(visualizerInterval);
  if (!isPlaying.value) {
    visualizerBars.value = Array(18).fill(5);
  }
  visualizerInterval = setInterval(() => {
    if (isPlaying.value) {
      visualizerBars.value = visualizerBars.value.map(() =>
        Math.floor(20 + Math.random() * 60)
      );
    }
  }, 200);
}

function getBarColor(height) {
  if (height > 50) return "#ffcc33";
  if (height > 30) return "#aaff33";
  return "#fff";
}

// Player controls
async function togglePlay() {
  if (!audioPlayer.value) return;
  try {
    if (isPlaying.value) {
      await audioPlayer.value.pause();
      isPlaying.value = false;
      visualizerBars.value = Array(18).fill(5);
    } else {
      await audioPlayer.value.play();
      isPlaying.value = true;
    }
  } catch (error) {
    console.error("Error during playback:", error);
  }
}

function stopTrack() {
  if (!audioPlayer.value) return;
  audioPlayer.value.pause();
  audioPlayer.value.currentTime = 0;
  isPlaying.value = false;
  currentTime.value = 0;
  visualizerBars.value = Array(18).fill(5);
}

function triggerDownload() {
  if (!song.value || !songDownloadUrl.value) return;
  const filename = `DJ Chris - ${song.value.song_title}.wav`;
  fetch(songDownloadUrl.value)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    })
    .catch((error) => console.error("Error downloading file:", error));
}

function updateCurrentTime() {
  if (audioPlayer.value && !isDragging.value) {
    currentTime.value = Math.floor(audioPlayer.value.currentTime);
  }
}

function setDuration() {
  if (audioPlayer.value) {
    duration.value = Math.floor(audioPlayer.value.duration);
  }
}

function handleAudioEnded() {
  isPlaying.value = false;
  currentTime.value = 0;
  if (audioPlayer.value) {
    audioPlayer.value.currentTime = 0;
  }
  visualizerBars.value = Array(18).fill(5);
}

function formatTime(seconds) {
  if (!seconds || isNaN(seconds)) return "00:00";
  const mins = Math.floor(seconds / 60)
    .toString()
    .padStart(2, "0");
  const secs = Math.floor(seconds % 60)
    .toString()
    .padStart(2, "0");
  return `${mins}:${secs}`;
}

// Slider Global Event Handlers

// Mouse events
function onMouseDown(e) {
  if (!progressTrack.value) return;
  isDragging.value = true;
  updateDragPosition(e);
  window.addEventListener("mousemove", onMouseMove);
  window.addEventListener("mouseup", onMouseUp);
}

function onMouseMove(e) {
  if (!isDragging.value) return;
  updateDragPosition(e);
}

function onMouseUp(e) {
  if (!isDragging.value) return;
  updateDragPosition(e);
  if (audioPlayer.value) {
    audioPlayer.value.currentTime = dragTime.value;
  }
  currentTime.value = dragTime.value;
  isDragging.value = false;
  window.removeEventListener("mousemove", onMouseMove);
  window.removeEventListener("mouseup", onMouseUp);
}

function updateDragPosition(e) {
  const rect = progressTrack.value.getBoundingClientRect();
  const offset = e.clientX - rect.left;
  let percentage = offset / rect.width;
  percentage = Math.max(0, Math.min(percentage, 1));
  dragTime.value = percentage * duration.value;
  currentTime.value = dragTime.value;
}

// Touch events
function onTouchStart(e) {
  if (!progressTrack.value) return;
  isDragging.value = true;
  updateTouchPosition(e);
  window.addEventListener("touchmove", onTouchMove, { passive: false });
  window.addEventListener("touchend", onTouchEnd);
}

function onTouchMove(e) {
  if (!isDragging.value) return;
  updateTouchPosition(e);
}

function onTouchEnd(e) {
  if (!isDragging.value) return;
  updateTouchPosition(e);
  if (audioPlayer.value) {
    audioPlayer.value.currentTime = dragTime.value;
  }
  currentTime.value = dragTime.value;
  isDragging.value = false;
  window.removeEventListener("touchmove", onTouchMove);
  window.removeEventListener("touchend", onTouchEnd);
}

function updateTouchPosition(e) {
  if (e.touches.length > 0) {
    const touch = e.touches[0];
    const rect = progressTrack.value.getBoundingClientRect();
    const offset = touch.clientX - rect.left;
    let percentage = offset / rect.width;
    percentage = Math.max(0, Math.min(percentage, 1));
    dragTime.value = percentage * duration.value;
    currentTime.value = dragTime.value;
  }
}
</script>

<style scoped>
/* Import fonts */
@import url("https://fonts.googleapis.com/css2?family=VT323&family=Share+Tech+Mono&display=swap");

:root {
  --bg-dark: #222427;
  --bg-player: #303539;
  --player-border: #404449;
  --display-bg: #374037;
  --display-text: #9eff9e;
  --display-glow: rgba(158, 255, 158, 0.4);
  --slider-bg: #1e1e1e;
  --slider-fill: #fd7e14;
  --control-bg: #2a2e32;
  --control-active: #9eff9e;
  --social-youtube: #ff0000;
  --social-tiktok: #00f2ea;
}

/* Base page styling */
.song-details-page {
  font-family: "Share Tech Mono", monospace;
  color: var(--display-text);
  background: linear-gradient(180deg, #1e2428 0%, #121416 100%);
  min-height: 100vh;
  padding-bottom: 30px;
}

/* App Bar */
.app-bar {
  background: linear-gradient(90deg, #2a3038, #343a40);
  padding: 15px 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #454a52;
}

.back-button {
  display: flex;
  align-items: center;
}

.back-link {
  display: flex;
  align-items: center;
  color: #dedede;
  text-decoration: none;
  font-weight: 500;
  transition: opacity 0.2s ease;
}

.back-link:hover {
  opacity: 0.8;
}

.back-icon {
  margin-right: 8px;
  font-size: 1.2rem;
}

/* Retro Player Container */
.retro-player-container {
  max-width: 700px;
  margin: 30px auto;
  padding: 0 15px;
}

/* Main Retro Player UI */
.retro-player {
  display: flex;
  background-color: var(--bg-player);
  border-radius: 15px 15px 5px 5px;
  border: 1px solid var(--player-border);
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}

/* Player Panels */
.player-panel {
  padding: 15px;
  box-sizing: border-box;
}

.visualizer-panel {
  flex: 1;
  background-color: var(--display-bg);
  position: relative;
  border-right: 1px solid var(--player-border);
  background-image: linear-gradient(
      rgba(0, 0, 0, 0) 97%,
      rgba(255, 255, 255, 0.05) 3%
    ),
    linear-gradient(90deg, rgba(0, 0, 0, 0) 97%, rgba(255, 255, 255, 0.05) 3%);
  background-size: 4px 4px;
}

.info-panel {
  flex: 1;
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
}

/* Time Display */
.time-display {
  font-family: "VT323", monospace;
  font-size: 42px;
  color: white;
  text-shadow: 0 0 10px var(--display-glow);
  margin-bottom: 15px;
}

/* Song Thumbnail Display - New */
.thumbnail-display {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--player-border);
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.song-thumbnail {
  width: 100%;
  height: auto;
  aspect-ratio: 1;
  object-fit: cover;
  box-shadow: 0 0 15px rgba(158, 255, 158, 0.3);
}

/* Audio Visualizer */
.visualizer {
  height: 80px;
  display: flex;
  align-items: flex-end;
  gap: 4px;
  margin-top: 10px;
}

.visualizer-bar {
  flex: 1;
  background-color: #fff;
  min-height: 10px;
  transition: height 0.2s ease;
}

.control-active {
  color: var(--control-active);
}

/* Track Information */
.track-title {
  font-family: "Share Tech Mono", monospace;
  font-size: 22px;
  color: white;
  margin-bottom: 5px;
  text-shadow: 0 0 5px var(--display-glow);
}

.track-artist {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 20px;
}

.track-meta {
  display: flex;
  font-size: 14px;
  gap: 8px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 20px;
}

/* Social Links - New */
.social-links {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 35px;
  border-radius: 6px;
  background-color: var(--control-bg);
  text-decoration: none;
  border: 1px solid #444;
  transition: all 0.2s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3),
    inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.youtube-button {
  border-bottom: 3px solid var(--social-youtube);
}

.youtube-button:hover {
  background-color: rgba(255, 0, 0, 0.2);
  border-color: var(--social-youtube);
}

.tiktok-button {
  border-bottom: 3px solid var(--social-tiktok);
}

.tiktok-button:hover {
  background-color: rgba(0, 242, 234, 0.2);
  border-color: var(--social-tiktok);
}

.social-button:active {
  transform: translateY(2px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.social-button .button-icon {
  color: #ddd;
  font-weight: bold;
  font-size: 16px;
}

/* Extra Controls */
.extra-controls {
  margin-top: auto;
}

.slider-control {
  position: relative;
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.slider-track {
  height: 8px;
  background-color: var(--slider-bg);
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.slider-fill {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
}

.slider-handle {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: #ddd;
  border-radius: 50%;
  left: 60%;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #333;
}

/* Button Controls */
.button-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.eq-button {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: var(--control-bg);
  border: 1px solid #555;
  color: #ddd;
  font-family: "Share Tech Mono", monospace;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-mini {
  height: 6px;
  background-color: var(--slider-bg);
  width: 60%;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.slider-mini-fill {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
  background-color: #4ae54a;
}

.mode-controls {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.mode-button {
  width: 40px;
  height: 30px;
  background-color: var(--control-bg);
  border: 1px solid #555;
  color: #ddd;
  border-radius: 4px;
  font-family: "Share Tech Mono", monospace;
  font-size: 14px;
}

/* Main Playback Control */
.main-playback-control {
  position: relative;
  height: 20px;
  margin: 0 10px;
  display: flex;
  align-items: center;
}

.progress-track {
  height: 8px;
  background-color: #222;
  width: 100%;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.progress-fill {
  position: absolute;
  height: 100%;
}

.progress-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #ddd;
  border-radius: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border: 2px solid #333;
  cursor: grab;
  pointer-events: none;
}

.progress-handle:active {
  cursor: grabbing;
}

/* Main Control Buttons */
.main-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 15px;
}

.control-button {
  width: 60px;
  height: 45px;
  background-color: var(--control-bg);
  border: 1px solid #444;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3),
    inset 0 1px 0 rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
}

.control-button:hover {
  background-color: #383d42;
}

.control-button:active {
  transform: translateY(2px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3),
    inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.button-icon {
  font-size: 20px;
  color: #ddd;
}

.download-button {
  background-color: #444;
  border-color: #555;
}

.download-button:hover {
  background-color: #505050;
}

/* Loading State */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: var(--display-text);
  animation: spin 1s ease infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Media queries for responsiveness */
@media (max-width: 600px) {
  .retro-player {
    flex-direction: column;
  }

  .visualizer-panel {
    border-right: none;
    border-bottom: 1px solid var(--player-border);
  }

  .time-display {
    font-size: 36px;
    color: white;
  }

  .visualizer {
    height: 80px;
  }

  .control-button {
    width: 50px;
    height: 40px;
  }

  .play-button {
    width: 60px;
  }

  .thumbnail-display {
    max-width: 90%;
    margin: 0 auto 15px;
  }
}
</style>
