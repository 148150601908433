<template>
  <!-- Retro-Themed App Header -->
  <div class="retro-app">
    <header class="retro-header">
      <div class="logo-container">
        <img
          src="@/assets/dj-chris-logo.jpg"
          alt="DJ Chris Logo"
          class="site-logo"
        />
      </div>
      <h1 class="site-title">DJ CHRIS</h1>
    </header>

    <!-- Main Content Area -->
    <div class="main-container">
      <!-- Search Bar with Retro Design -->
      <div class="search-container">
        <div class="search-bar">
          <i class="search-icon">[SEARCH]</i>
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Find your track..."
            class="search-input"
          />
        </div>
      </div>

      <!-- Body Content -->
      <div class="content">
        <!-- Loading State -->
        <div v-if="loading" class="loading-text">
          <div class="retro-display-box">
            <h2>LOADING...</h2>
            <p>Please wait while we fetch your tracks</p>
          </div>
        </div>
        <!-- No Songs Found Message -->
        <div v-else-if="filteredSongs.length === 0" class="no-songs-found">
          <div class="retro-display-box">
            <h2>NO TRACKS FOUND</h2>
            <p>Try something else or explore the latest releases</p>
          </div>
        </div>
        <!-- Songs Grid with Retro Animation -->
        <div v-else class="retro-grid-container">
          <div
            v-for="song in filteredSongs"
            :key="song.id"
            class="retro-grid-item"
            @click="goToSongDetails(song)"
          >
            <div class="retro-thumbnail-container">
              <img
                :src="getThumbnailUrl(song.thumbnail_name)"
                :alt="song.title"
                class="retro-thumbnail-image"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Retro Footer -->
      <footer class="retro-footer">
        <div class="footer-text">DJ CHRIS © {{ new Date().getFullYear() }}</div>
      </footer>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { supabase } from "../lib/supabaseClient";

export default {
  setup() {
    const songs = ref([]);
    const searchQuery = ref("");
    const loading = ref(true);
    const router = useRouter();

    // Fetch songs on component mount
    onMounted(() => {
      document.body.style.backgroundColor = "#121212"; // Darker background
      getSongs();
    });

    // Fetch songs from the database
    async function getSongs() {
      loading.value = true;
      const { data, error } = await supabase.from("songs").select();
      if (error) {
        console.error("Error fetching songs:", error);
      } else {
        songs.value = data;
      }
      loading.value = false;
    }

    // Generate public thumbnail URL
    function getThumbnailUrl(thumbnailName) {
      const response = supabase.storage
        .from("thumbnails")
        .getPublicUrl(thumbnailName);
      return response.data["publicUrl"];
    }

    // Format created_at to just year
    function formatYear(dateString) {
      if (!dateString) return "";
      return new Date(dateString).getFullYear();
    }

    // Navigate to song details
    function goToSongDetails(song) {
      if (router) {
        router.push({ name: "song-details", params: { songId: song.id } });
      } else {
        console.error("Router is not initialized");
      }
    }

    const filteredSongs = computed(() => {
      const songsCopy = [...songs.value];
      const sortedSongs = songsCopy.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      if (searchQuery.value) {
        return sortedSongs.filter((song) =>
          song.song_title
            ?.toLowerCase()
            .includes(searchQuery.value.toLowerCase())
        );
      }
      return sortedSongs;
    });

    return {
      songs,
      searchQuery,
      loading,
      filteredSongs,
      getThumbnailUrl,
      goToSongDetails,
      formatYear,
    };
  },
};
</script>

<style scoped>
/* Import Retro Fonts */
@import url("https://fonts.googleapis.com/css2?family=VT323&family=Share+Tech+Mono&family=Press+Start+2P&display=swap");

/* Retro Color Variables */
:root {
  --retro-bg-dark: #222427;
  --retro-bg-darker: #0a0a0a;
  --retro-display-bg: #374037;
  --retro-display-text: #9eff9e;
  --retro-display-glow: rgba(158, 255, 158, 0.4);
  --retro-accent: #fd7e14;
  --retro-accent-alt: #ffcc33;
  --retro-primary: #4ae54a;
  --retro-border: #3a3f44;
  --retro-panel: #303539;
}

/* Base Retro Styling */
.retro-app {
  font-family: "Share Tech Mono", monospace;
  background: linear-gradient(180deg, #1e2428 0%, #121416 100%);
  min-height: 100vh;
  color: var(--retro-display-text);
  display: flex;
  flex-direction: column;
}

/* Header Styling */
.retro-header {
  background: var(--retro-bg-dark);
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 2px solid var(--retro-border);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.5);
}

.site-title {
  font-family: "Press Start 2P", cursive;
  font-size: 1.5rem;
  color: white; /* Updated to white */
  text-shadow: 0 0 10px var(--retro-display-glow);
  margin: 10px 0 5px 0;
  letter-spacing: 2px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-logo {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--retro-border);
  box-shadow: 0 0 15px rgba(158, 255, 158, 0.3);
  transition: transform 0.3s ease;
}

.site-logo:hover {
  transform: scale(1.05);
}

/* Main Container */
.main-container {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Search Bar Styling */
.search-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.search-bar {
  position: relative;
  width: 60%;
  max-width: 500px;
  display: flex;
  align-items: center;
  background-color: var(--retro-display-bg);
  border: 2px solid var(--retro-border);
  border-radius: 8px;
  padding: 8px 15px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
}

.search-bar:focus-within {
  box-shadow: 0 0 0 3px var(--retro-primary), inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.search-icon {
  margin-right: 10px;
  color: var(--retro-display-text);
  font-family: "Share Tech Mono", monospace;
  font-size: 0.9rem;
}

.search-input {
  background: transparent;
  border: none;
  color: white;
  font-size: 1rem;
  padding: 8px 0;
  width: 100%;
  outline: none;
  font-family: "Share Tech Mono", monospace;
}

.search-input::placeholder {
  color: rgba(158, 255, 158, 0.5);
}

/* Retro Grid Layout */
.retro-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
  padding: 10px 0;
}

.retro-grid-item {
  cursor: pointer;
  border: 2px solid var(--retro-border);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: var(--retro-panel);
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.retro-grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4), 0 0 15px var(--retro-display-glow);
  border-color: var(--retro-primary);
}

.retro-thumbnail-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Create a 1:1 aspect ratio */
  overflow: hidden;
}

.retro-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.retro-grid-item:hover .retro-thumbnail-image {
  transform: scale(1.05);
}

/* Loading and No Songs Found */
.loading-text,
.no-songs-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.retro-display-box {
  background-color: var(--retro-display-bg);
  border: 2px solid var(--retro-border);
  border-radius: 8px;
  padding: 30px 40px;
  text-align: center;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.6);
}

.retro-display-box h2 {
  font-family: "VT323", monospace;
  font-size: 2rem;
  margin-bottom: 15px;
  color: var(--retro-display-text);
  text-shadow: 0 0 10px var(--retro-display-glow);
}

.retro-display-box p {
  font-size: 1rem;
  font-family: "Share Tech Mono", monospace;
  color: rgba(158, 255, 158, 0.8);
}

/* Retro Footer */
.retro-footer {
  margin-top: 40px;
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid var(--retro-border);
}

.footer-text {
  font-family: "Share Tech Mono", monospace;
  font-size: 0.9rem;
  color: rgba(158, 255, 158, 0.7);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .site-title {
    font-size: 1.2rem;
  }

  .search-bar {
    width: 80%;
  }

  .retro-grid-container {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .site-title {
    font-size: 1rem;
  }

  .search-bar {
    width: 90%;
  }

  .retro-grid-container {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 10px;
  }

  .retro-display-box {
    padding: 20px;
  }

  .retro-display-box h2 {
    font-size: 1.5rem;
  }
}
</style>
